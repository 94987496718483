// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-complaints-procedure-js": () => import("./../../../src/pages/complaints-procedure.js" /* webpackChunkName: "component---src-pages-complaints-procedure-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-how-it-works-questions-js": () => import("./../../../src/pages/how-it-works/questions.js" /* webpackChunkName: "component---src-pages-how-it-works-questions-js" */),
  "component---src-pages-how-it-works-terms-conditions-js": () => import("./../../../src/pages/how-it-works/terms-conditions.js" /* webpackChunkName: "component---src-pages-how-it-works-terms-conditions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-report-claims-js": () => import("./../../../src/pages/report-claims.js" /* webpackChunkName: "component---src-pages-report-claims-js" */),
  "component---src-pages-sign-up-to-fml-js": () => import("./../../../src/pages/sign-up-to-fml.js" /* webpackChunkName: "component---src-pages-sign-up-to-fml-js" */),
  "component---src-pages-what-is-motor-legal-protection-js": () => import("./../../../src/pages/what-is-motor-legal-protection.js" /* webpackChunkName: "component---src-pages-what-is-motor-legal-protection-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/news-template.js" /* webpackChunkName: "component---src-templates-news-template-js" */)
}

